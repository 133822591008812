/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, H2, P, useLang } from "@manulife/mux";
import Iframe from "react-iframe";
import ClipLoader from "react-spinners/ClipLoader";
import { insertCustomer, storeURL } from "../actions/confirmationActions";
import {
  checkSigners,
  createEnvelope
} from "../methods/confirmationPageMethods";
import { getDocumentURL } from "../methods/eSignatureMethods";
import doneImage from "../img/done.jpg";
import { storeLanguage } from "../actions/formSelectActions";
import localizationObject from "../localization";

function Confirmation() {
  const dispatch = useDispatch();
  const boundInsertCustomer = (
    tempEnvelopeState,
    tempPageLoadState,
    tempRolesState,
    ownerFirstName,
    ownerLastName,
    envelopeID,
    accessCode
  ) =>
    dispatch(
      insertCustomer(
        tempEnvelopeState,
        tempPageLoadState,
        tempRolesState,
        ownerFirstName,
        ownerLastName,
        envelopeID,
        accessCode
      )
    );
  const boundStoreURL = docuSignURL => dispatch(storeURL(docuSignURL));
  const boundStoreLanguage = strings => dispatch(storeLanguage(strings));
  const tempEnvelopeState = useSelector(state => state.envelope);
  const tempPageLoadState = useSelector(state => state.pageLoad);
  const tempRolesState = useSelector(state => state.roles);
  const signers = useSelector(state => state.envelope.prefillSigners);
  const advisorSigning = useSelector(state => state.envelope.advisorSigning);
  const advisorToggle = useSelector(state => state.envelope.advisorToggle);
  const buttonDisable = useSelector(state => state.envelope.disabled);
  const [accessCode, setAccessCode] = useState("");
  const [hasAccessCode, setHasAccessCode] = useState(false);
  const [disableCreate, setDisableCreate] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showDone, setShowDone] = useState(false);
  const [prefillSigning, setPrefillSigning] = useState(true);
  const [counter, setCounter] = useState(0);
  const [nextSignerText, setNextSignerText] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [SRDoneSigningText, setSRDoneSigningText] = useState("");
  const [language, setLanguage] = useState({});
  const lang = useLang();

  // translation of french/english using LANG PROVIDER
  useEffect(() => {
    if (lang.length > 0) {
      setLanguage(localizationObject[lang]);
    } else {
      setLanguage(localizationObject["en-CA"]);
    }
  }, [lang]);

  const createEnvelopeText = language.CreateEnvelope
    ? language.CreateEnvelope
    : "Create Envelope";
  const requestSentText = language.RequestSent
    ? language.RequestSent
    : "Request Sent";
  const launchAdvisorViewText = language.LaunchAdvisorView
    ? language.LaunchAdvisorView
    : "Launch Advisor View";
  const [envelopeCreated, setEnvelopeCreated] = useState(false);

  useEffect(() => {
    if (envelopeCreated) {
      nextSigner();
    }
  }, [envelopeCreated]);

  useEffect(() => {
    setAccessCode(
      signers.find(item => {
        return item.accessCode;
      })
    );
  }, [accessCode]);

  useEffect(() => {
    if (signers.length > 0 && !envelopeCreated) {
      createEnvelope(
        signers,
        tempEnvelopeState,
        tempPageLoadState,
        tempRolesState,
        localizationObject[language],
        setDisableCreate,
        setEnvelopeCreated,
        setShowForm,
        setError,
        setLoading,
        boundStoreURL,
        boundInsertCustomer
      );
      setEnvelopeCreated(true);
    }
    if (advisorToggle) {
      setShowDone(true);
      setNextSignerText(false);
    }
  }, [tempEnvelopeState, tempPageLoadState, tempRolesState]);

  const nextSigner = async () => {
    let signerError = "";
    if (counter > 0) {
      signerError = await checkSigners(
        counter,
        tempEnvelopeState,
        tempPageLoadState,
        tempRolesState,
        language
      );
      setError(signerError);
    }
    if (signerError.length > 0) {
      return;
    }

    let tempCounter = counter;
    setCounter((tempCounter += 1));

    if (nextSignerText === language.Done) {
      setShowDone(true);
      setSRDoneSigningText(language.Finished);
    }

    const inPersonSigners = signers.filter(
      item =>
        !item.hasOwnProperty("accessCode") && !item.roleName.includes("copy")
    );

    if (signers.length > tempCounter) {
      if (signers[tempCounter].hasOwnProperty("accessCode")) {
        setHasAccessCode(true);
      } else {
        setHasAccessCode(false);
      }
    }

    if (counter === 0) {
      if (
        counter === inPersonSigners.length - 1 ||
        inPersonSigners.length === 0
      ) {
        setNextSignerText(language.Done);
      } else {
        setNextSignerText(language.LaunchClientView);
      }
      if (error.length === 0) {
        setShowForm(true);
      }
    } else if (counter < inPersonSigners.length) {
      setNextSignerText(language.Next);

      setDisableNext(true);
      if (signers[counter].roleName === "Advisor Optional Prefill") {
        setPrefillSigning(true);
      } else {
        setPrefillSigning(false);
      }

      getDocumentURL(
        tempEnvelopeState.envelopeId,
        signers[counter],
        tempRolesState.accountId
      ).then(docuSignURL => {
        if (docuSignURL.toString().includes("Error")) {
          setError(language.InvalidNextError);
          setDisableNext(false);
        } else {
          setError("");
          boundStoreURL(docuSignURL);
          setDisableNext(false);
        }
      });
      if (counter === inPersonSigners.length - 1) {
        setNextSignerText(language.Done);
      }
    } else {
      setShowForm(false);
    }
  };

  return language.PleaseWait ? (
    <div className="page center">
      <p className="sr-only" aria-live="polite">
        {SRDoneSigningText}
      </p>
      {!showForm && !showDone && tempEnvelopeState.passed ? (
        <p>{language.InPersonInstructions1}</p>
      ) : null}
      {showForm &&
      tempEnvelopeState.passed &&
      prefillSigning &&
      !hasAccessCode &&
      signers.every(
        item => item.roleName !== "Advisor" && item.roleName !== "Conseiller"
      ) ? (
        <div>
          <P aria-live="polite">{language.InPersonInstructions2}</P>
          <P>{language.InPersonInstructions3}</P>
        </div>
      ) : null}
      {showForm &&
      tempEnvelopeState.passed &&
      prefillSigning &&
      hasAccessCode &&
      signers.every(
        item => item.roleName !== "Advisor" && item.roleName !== "Conseiller"
      ) ? (
        <div>
          <P aria-live="polite">{language.InPersonInstructions2}</P>
        </div>
      ) : null}
      {showForm &&
      tempEnvelopeState.passed &&
      (signers.every(
        item => item.roleName === "Advisor" || item.roleName === "Conseiller"
      ) ||
        !prefillSigning) ? (
        <div>
          <P aria-live="polite">{language.InPersonSignerInstructions}</P>
        </div>
      ) : null}
      {accessCode && showDone && signers.length > 1 ? (
        <div autoFocus="true">
          <P aria-live="polite">{language.BothInstructions}</P>
        </div>
      ) : null}
      {accessCode ? (
        <div>
          <H2 aria-live="polite">
            <strong>
              <em>
                {language.AccessCode}
                {accessCode.accessCode}
              </em>
            </strong>
          </H2>
          <P>{language.AccessCodeInstructions1}</P>
          <P>{language.AccessCodeInstructions2}</P>
          <P>{language.EmailInstructions}</P>
        </div>
      ) : null}

      {showForm ? (
        <div className="margin">
          <Iframe
            key={counter}
            url={tempEnvelopeState.docuSignURL}
            width="100%"
            height="600px"
            sandbox={[
              "allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts allow-downloads"
            ]}
          />
        </div>
      ) : null}
      {showDone && error === "" && buttonDisable === true ? (
        <div>
          {advisorSigning ? " " : <P>{language.Finished}</P>}
          <img
            src={doneImage}
            alt="Done signing"
            data-testid="doneImage"
            aria-label="Done signing"
          />
        </div>
      ) : null}
      <div aria-live="assertive">
        <P color="red" data-testid="error">
          {error}
        </P>
      </div>
      {!showForm && !showDone ? (
        <div>
          <ClipLoader size={36} color="#123abc" loading={loading} />
        </div>
      ) : null}
      {showForm ? (
        <Button
          ariaLabel={
            !showForm && nextSignerText === ""
              ? launchAdvisorViewText
              : nextSignerText
          }
          disabled={!tempEnvelopeState.passed || showDone || disableNext}
          onClick={() => nextSigner()}
        >
          {!showForm && nextSignerText === ""
            ? launchAdvisorViewText
            : nextSignerText}
        </Button>
      ) : null}
    </div>
  ) : null;
}

export default Confirmation;
