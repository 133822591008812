/* eslint-disable no-unused-vars */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "unorm";
import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import AppWrapper from "./App";

const values = require("object.values");

values.shim();

// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(<AppWrapper />, document.getElementById("root"));
