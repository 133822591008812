/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
import moment from "moment";
import "react-table/react-table.css";
import React, { useState, useEffect, useMemo } from "react";
import "./EnvelopeStatus.css";
import "./EnvelopeStatusMUX.css";
import "./SendEnvelope.css";
import {
  Button,
  DatePicker,
  Tooltip,
  TextInput,
  DataTableExperimental,
  useLang
} from "@manulife/mux";
import { Element, scroller } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import {
  queryCustomers,
  cancelStatus,
  showInfo
} from "./actions/envelopeStatusActions";
import localizationObject from "./localization";

const redirectURL = window.REACT_APP_CIAMBFF_URL;

export default function EnvelopeStatus() {
  const dateSub = moment().subtract(30, "day");
  const dateSentVal = dateSub.format("YYYY-MM-DD");
  const dispatch = useDispatch();

  const [RepsourceID, setRepsourceID] = useState("");
  const [visible, setvisible] = useState(true);
  const [Row, setRow] = useState([]);
  const [signers, setsigners] = useState([]);
  const [dateValue, setdateValue] = useState(dateSentVal);
  const [invalidDate, setInvalidDate] = useState(false);
  const [SigningPartyName, setSigningPartyName] = useState(undefined);
  const [PolicyNumber, setPolicyNumber] = useState(undefined);
  const [language, setLanguage] = useState([]);

  const lang = useLang(); // language selector LANG PROVIDER
  const params = useSelector(state => state.pageLoad.params);
  const signersData = useSelector(state => state.envelope.signers);
  const RowData = useSelector(state => state.status.Row);
  const routeState = useSelector(state => state.pageLoad.routeState);
  const ciamSession = useSelector(state => state.pageLoad.ciamSession);

  useEffect(() => {
    const { token } = routeState;
    if (!ciamSession) {
      if (routeState === undefined) {
        window.location.href = redirectURL;
      }

      if (token === undefined || token === "") {
        window.location.href = redirectURL;
      }
    }
  }, [routeState, ciamSession]);

  useEffect(() => {
    setRow(RowData);
    setRepsourceID(params.RepsourceID);
    setsigners(signersData);
  }, [Row, params, signers, signersData, RowData, ciamSession, routeState]);

  // translation of french/english using LANG PROVIDER
  useEffect(() => {
    if (lang.length > 0) {
      setLanguage(localizationObject[lang]);
    } else {
      setLanguage(localizationObject["en-CA"]);
    }
  }, [lang]);

  function datepicker(newValue) {
    if (
      Object.prototype.toString.call(dateValue) === "[object String]" &&
      dateValue === ""
    ) {
      setdateValue("  ");
      setInvalidDate(false);
    } else {
      setdateValue(newValue);
      setInvalidDate(false);
    }
  }

  function dateInvalid() {
    setInvalidDate(true);
  }

  function changeSigningPartyName(value) {
    if (value) {
      setSigningPartyName(value);
    } else {
      setSigningPartyName(undefined);
    }
  }

  function changePolicy(val) {
    if (val) {
      setPolicyNumber(val);
    } else {
      setPolicyNumber(undefined);
    }
  }

  function showInfoFunc(e, row) {
    const data = row.original;
    setvisible(false);
    setsigners([]);
    dispatch(showInfo(data, language));

    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  }

  function onCancel() {
    document.getElementById("Form").reset();
    dispatch(cancelStatus());
    setvisible(true);
    setPolicyNumber("");
    setSigningPartyName("");
    setPolicyNumber(undefined);
    setSigningPartyName(undefined);
    setdateValue("  ");
    setInvalidDate(false);
  }

  function onSubmit(e) {
    const prevValue = {
      date: dateValue,
      policy: PolicyNumber,
      name: SigningPartyName
    };
    setsigners([]);
    dispatch(
      queryCustomers(
        RepsourceID,
        PolicyNumber,
        SigningPartyName,
        dateValue,
        language
      )
    );
    setPolicyNumber(prevValue.policy);
    setSigningPartyName(prevValue.name);
    setdateValue(prevValue.date);
  }

  // useMemo prevents unnecessary re-renders that causes flickering bug on MUX
  // column header for tables
  const TableDataMain = useMemo(
    () => [
      {
        header: <span className="TableHeader">{language.DateSent}</span>,
        accessorKey: "DateSent", // String-based value accessors!
        Cell: row => (
          <span className="TableBody">
            {moment.utc(row.value).format("MM/DD/YYYY")}
          </span>
        ),
        Footer: <span className="TableHeader"> </span>
      },
      {
        header: <span className="TableHeader">{language.Owner}</span>,
        accessorKey: "ClientName",
        Cell: row => <span className="TableBody">{row.getValue()}</span>
      },
      {
        header: <span className="TableHeader">{language.PolicyNumber}</span>,
        accessorKey: "policyNumber", // Required because our accessor is not a string
        Cell: row => <span className="TableBody">{row.getValue()}</span>
      },
      {
        header: (
          <span className="TableHeader">{language.GetAdditionalInfo}</span>
        ),
        accessorKey: "Additional",
        Cell: row => (
          <Button className="TableBody" onClick={e => showInfoFunc(e, row.row)}>
            {language.Click}
          </Button>
        )
      }
    ],
    [Row]
  );

  const TableDataInfo = useMemo(
    () => [
      {
        header: <span className="TableHeader">{language.DateSent}</span>,
        accessorKey: "date",
        Cell: row => (
          <span className="TableBodyInfo">
            {moment.utc(row.value).format("MM/DD/YYYY")}
          </span>
        )
      },
      {
        header: <span className="TableHeader">{language.FormNumber}</span>,
        accessorKey: "docName",
        Cell: row => <span className="TableBodyInfo">{row.getValue()}</span>
      },
      {
        header: <span className="TableHeader">{language.SigningParty}</span>,
        accessorKey: "signersName",
        Cell: row => <span className="TableBodyInfo">{row.getValue()}</span>
      },
      {
        header: <span className="TableHeader">{language.EmailAddress}</span>,
        accessorKey: "email",
        Cell: row => <span className="TableBodyInfo">{row.getValue()}</span>
      },
      {
        header: <span className="TableHeader">{language.AccessCode}</span>,
        accessorKey: "password",
        Cell: row => (
          <span className="TableBodyInfo">
            {row.getValue() === undefined ? "---" : row.getValue()}
          </span>
        )
      },
      {
        header: <span className="TableHeader">{language.Status}</span>,
        accessorKey: "status",
        Cell: row => <span className="TableBodyInfo">{row.getValue()}</span>
      }
    ],
    [signers]
  );

  return (
    <div>
      {language !== undefined ? (
        <div>
          <div className="FormWrapper">
            <h1 className="title">{language.EnvelopeStatusTitle}</h1>
            <div className="WrapperWidth">
              <form id="Form">
                <div className="Rtable">
                  <div className="Rtable-cell Rtable-cell-header Rtable-cell--2of4">
                    <h4 color="white">{language.PolicyNumber}</h4>
                  </div>
                  <div
                    className="Rtable-cell Rtable-cell-dark Rtable-cell--2of4 "
                    style={{ overflow: "visible" }}
                  >
                    <TextInput
                      id="PolicyNumber"
                      maxLength="15"
                      placeholder={language.Optional}
                      ariaLabel={`${language.PolicyNumber} ${language.Optional}`}
                      value={PolicyNumber}
                      customStyle={{
                        rootStyle: {
                          maxWidth: "192px"
                        }
                      }}
                      onChange={val => {
                        changePolicy(val);
                      }}
                      onFocus={val => {
                        changePolicy(val);
                      }}
                      onBlur={val => {
                        changePolicy(val);
                      }}
                    />
                  </div>
                  <div className="Rtable-cell Rtable-cell-header Rtable-cell--2of4 ">
                    <h4 color="white">{language.LastName}</h4>
                  </div>
                  <div
                    className="Rtable-cell Rtable-cell-dark Rtable-cell--2of4 "
                    style={{ overflow: "visible" }}
                  >
                    <TextInput
                      id="SigningPartyName"
                      value={SigningPartyName}
                      ariaLabel={`${language.LastName} ${language.Optional}`}
                      placeholder={language.Optional}
                      customStyle={{
                        rootStyle: {
                          maxWidth: "192px"
                        }
                      }}
                      onChange={val => changeSigningPartyName(val)}
                      onFocus={val => {
                        changeSigningPartyName(val);
                      }}
                      onBlur={val => {
                        changeSigningPartyName(val);
                      }}
                    />
                  </div>
                  <div
                    className="Rtable-cell Rtable-cell-dark Rtable-cell--4of4"
                    style={{ height: "165px", overflow: "visible" }}
                  >
                    <DatePicker
                      dateFormat="YYYY-MM-DD"
                      labelFormat={language.DateFormatISO}
                      label={language.DateRange}
                      value={dateValue}
                      required={false}
                      dateInvalidError={`${language.DateError} ${language.DateFormat}`}
                      dateRangeError={language.DateTracking}
                      onError={e => {
                        dateInvalid(e);
                      }}
                      placeholder={language.Optional}
                      tooltip={<Tooltip content={language.DateTracking} />}
                      disabledDays={{
                        before: new Date(
                          new Date().setDate(new Date().getDate() - 30)
                        ),
                        after: new Date()
                      }}
                      onChange={newValue => datepicker(newValue)}
                      customStyle={{
                        rootStyle: {
                          maxWidth: "2000px"
                        }
                      }}
                    />
                  </div>
                </div>
              </form>
              <div className="Buttons">
                <Button
                  disabled={invalidDate}
                  className="ButtonSubmit"
                  onClick={e => onSubmit(e)}
                >
                  {language.Search}
                </Button>
                <Button className="ButtonDiscard" onClick={() => onCancel()}>
                  {language.Discard}
                </Button>
              </div>
            </div>
            <div className="mt-2">
              <DataTableExperimental
                data={Row}
                id="Table"
                pageSize={10}
                columns={TableDataMain}
                sorting
                emptyTableMessage={
                  <span id="Table">{language.TableNoResult}</span>
                }
              />
            </div>
            <div className={visible ? "hidden" : "mt-3"} margin-top="1%">
              <Element name="scroll-to-element" className="element" />
              <DataTableExperimental
                id="Table"
                data={signers}
                pageSize={2}
                sorting
                columns={TableDataInfo}
                emptyTableMessage={
                  <span id="Table">{language.TableNoResult}</span>
                }
              />
              <div>
                <label className="col-md-12 control-label mt-1 mb-1">
                  {language.FooterDesc1}
                </label>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
