/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TextInput,
  DataTableExperimental,
  Button,
  Modal,
  H1,
  P,
  useLang
} from "@manulife/mux";
import { Link } from "react-router-dom";
import { getForms, getDocImage } from "../methods/eSignatureMethods";
import { getRoleData, search } from "../methods/formSelectMethods";
import {
  responed,
  loadInfo,
  dismissWarning,
  storeLanguage
} from "../actions/formSelectActions";
import warningImg from "../img/warning.png";
import prefillImage from "../img/checkmark.png";
import "../App.css";
import localizationObject from "../localization";

function FormSelect() {
  const redirectURL = window.REACT_APP_CIAMBFF_URL;
  const dispatch = useDispatch();
  const boundResponed = (message, disabled, passed) =>
    dispatch(responed(message, disabled, passed));
  const boundStoreLanguage = strings => dispatch(storeLanguage(strings));
  const boundLoadInfo = routeState => dispatch(loadInfo(routeState));
  const boundDismissWarning = flag => dispatch(dismissWarning(flag));
  const formInfo = useSelector(state => state.pageLoad.routeState);
  const [formNumberAndName, setformNumberAndName] = useState(
    formInfo.formnumber
  );
  const [forms, setForms] = useState([]);
  const [table, setTable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const timeoutWarning = useSelector(state => state.pageLoad.dismissWarning);
  const isCIAM = useSelector(state => state.pageLoad.ciamSession);
  const [imageSrc, setImageSrc] = useState("");
  const [previewButtonState, setPreviewButtonState] = useState(false);
  const [previewText, setPreviewText] = useState("Preview eForm");
  const [Language, setLanguage] = useState({});
  const lang = useLang();

  useEffect(() => {
    if (lang.length > 0) {
      setLanguage(localizationObject[lang]);
    } else {
      setLanguage(localizationObject["en-CA"]);
    }
  }, [lang]);

  useEffect(() => {
    async function loadPageHandler() {
      let token = "";

      if (formInfo === undefined) {
        window.location.href = redirectURL;
      }
      try {
        token = formInfo.token;
      } catch (error) {
        boundResponed(Language.FieldError, false, false);
      }

      getForms()
        .then(res => {
          setPreviewText(
            Language.PreviewForm ? Language.PreviewForm : "Preview eForm"
          );
          const tableData = res.map((item, index) => {
            return {
              FormNumberAndName: item.RepsourceFormNumberAndName,
              Select: (
                <Link to="/Signers">
                  <Button
                    ariaLabel={`${Language.SRSelectForm1} ${item.RepsourceFormNumberAndName} ${Language.SRSelectForm2}`}
                    onClick={() => {
                      document.getElementById("root").focus();
                      boundLoadInfo({
                        ...formInfo,
                        formnumber: item.RepsourceFormNumber,
                        formLanguage: item.RepsourceLanguage
                      });
                    }}
                    customStyle={{
                      buttonStyle: {
                        background: "rgb(236, 100, 83)",
                        minWidth: "20px",
                        padding: "2px"
                      }
                    }}
                  >
                    {item.RepsourceFormNumber}
                  </Button>
                </Link>
              ),
              Prefill: item.Prefill ? (
                <img
                  aria-label="Form is pre-fillable"
                  src={prefillImage}
                  alt={Language.PrefillForm}
                  width="30px"
                  height="30px"
                  style={{ marginLeft: "18%" }}
                />
              ) : (
                ""
              ),
              Preview: (
                <div>
                  <Button
                    key={index}
                    ariaLabel={`${Language.SRPreviewForm} ${item.RepsourceFormNumberAndName}`}
                    disabled={previewButtonState}
                    onClick={() => {
                      setPreviewButtonState(true);
                      getRoleData(
                        item.RepsourceFormNumber,
                        item.RepsourceLanguage
                      ).then(result => {
                        getDocImage(result.accountId, result.templateId).then(
                          val => {
                            setImageSrc(val);
                            setOpenModal(true);
                          }
                        );
                      });
                    }}
                    customStyle={{
                      buttonStyle: {
                        background: "rgb(236, 100, 83)",
                        minWidth: "20px",
                        padding: "2px"
                      }
                    }}
                  >
                    {previewButtonState === false
                      ? previewText
                      : Language.PleaseWait}
                  </Button>
                </div>
              )
            };
          });
          setForms(tableData);
          setTable(
            formNumberAndName.length > 0
              ? tableData.filter(item =>
                  item.FormNumberAndName.includes(formNumberAndName)
                )
              : tableData
          );
        })
        .catch(err => {});
    }
    loadPageHandler();
  }, [previewButtonState, previewText, Language]);

  const tableHeader = useMemo(
    () => [
      {
        header: Language.FormName,
        accessorKey: "FormNumberAndName"
      },
      {
        header: Language.Select,
        accessorKey: "Select"
      },
      {
        header: Language.PrefillForm,
        accessorKey: "Prefill"
      },

      {
        header: Language.PreviewForm,
        accessorKey: "Preview"
      }
    ],
    [previewText, Language]
  );

  return Language.FormSelect ? (
    <div className="page">
      <Modal
        isOpen={openModal}
        ariaDescribedBy="content"
        ariaLabelledBy={Language.PreviewForm}
        customStyle={{
          modalStyle: {
            textAlign: "center",
            width: "60%",
            height: "70%"
          }
        }}
        onClose={() => {
          setPreviewButtonState(false);
          setOpenModal(false);
        }}
      >
        <P id="content">{Language.PreviewText}</P>
        <div>
          <img
            src={`data:image/png;base64, ${imageSrc}`}
            alt={Language.PreviewForm}
          />
        </div>
      </Modal>

      {!timeoutWarning ? (
        <div className="timeoutWarning">
          <img
            src={warningImg}
            alt="warning"
            width="60px"
            height="60px"
            style={{ float: "left" }}
          />
          <div className="timeoutButton">
            <Button
              ariaLabel={Language.SRCloseDialog}
              variant={Button.VARIANT.TERTIARY_NOICON}
              onClick={() => boundDismissWarning(true)}
            >
              X
            </Button>
          </div>
          <br />
          <P>{isCIAM ? Language.timingError : Language.TimeoutWarning}</P>
        </div>
      ) : null}
      <div className="padding">
        <H1>{Language.FormSelect}</H1>
        <P>{Language.FormInstructions}</P>
        <label className="m1" htmlFor="Form Number">
          {Language.FormNumber}
          <br />
          <TextInput
            id="FormNumberAndName"
            ariaLabel={Language.Search}
            placeholder={Language.Search}
            value={formNumberAndName}
            customStyle={{
              rootStyle: {
                maxWidth: "70%"
              }
            }}
            onChange={value =>
              search(value, forms, setformNumberAndName, setTable)
            }
          />
        </label>
      </div>
      <div className="padding">
        <DataTableExperimental
          columns={tableHeader}
          id="myTable"
          data={table}
          // tdStyler={function noRefCheck() {
          //   // to resolve sonar issue with empty function
          // }}
        />
      </div>
    </div>
  ) : null;
}

export default FormSelect;
