/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import {
  SESSION_INFO_CIAM,
  REFRESH_SESSION_CIAM,
  REFRESH_SESSION_CIAM_PROGRESS,
  LOGOUT_SESSION_PROGRESS
} from "./types";
import { generatePass, setAxiosHeaders } from "../methods/eSignatureMethods";
import Language from "../localization";

const redirectURL = window.REACT_APP_CIAMBFF_URL;
const OK_RESPONSE = 200;

export const bffSessionInfo = (sessionInfo) => async (dispatch) => {
  setAxiosHeaders(sessionInfo.esigApiToken);
  let formnumber = "";

  if (sessionInfo.formnumber) {
    formnumber = sessionInfo.formnumber;
  }

  let language = "en";
  if (sessionInfo.preferredlanguage === "French") {
    language = "fr";
  }

  console.log(`preferred language AP : ${language}`);
  if(language === "fr")
  {
    sessionInfo.strings = Language['fr-CA'];
  }
  else{
    sessionInfo.strings = Language['en-CA'];
  }


  const routeState = {
    location: "./",
    formnumber,
    token: sessionInfo.esigApiToken
  };

  sessionInfo.preferredlanguage = language;
  sessionInfo.routeState = routeState;
  sessionInfo.accessCode = await generatePass();

  dispatch({
    type: SESSION_INFO_CIAM,
    sessionInfo
  });
};

export const bffRefreshSession = () => async (dispatch) => {
  try {
    const response = await fetch(`${redirectURL}/refreshSession`, {
      method: "GET",
      credentials: "include"
    });
    if (response.status === OK_RESPONSE) {
      dispatch({
        type: REFRESH_SESSION_CIAM,
        data: Date.now()
      });
    }
    const refSessionData = await response.json();
    return {
      status: response.status,
      data: refSessionData
    };
  } catch (e) {
    return false;
  }
};

/**
 *
 * Redirect to /ciam/?formnumber={formNumber}&logout
 * set the logout=true as parameter
 * this should call the ciam/userlogout route from ciam
 * Set the LOGOUT_SESSION_PROGRESS to true
 *
 *
 * @param {string} formNumber
 * @returns
 */
export const bffLogoutSession = (formNumber) => async (dispatch) => {
  formNumber = formNumber === undefined ? "" : formNumber;

  dispatch({
    type: LOGOUT_SESSION_PROGRESS
  });
  window.location.assign(`
    ${redirectURL}/?formnumber=${formNumber}&logout=true`);
};

export const bffRefreshSessionInProgress = () => async (dispatch) => {
  dispatch({
    type: REFRESH_SESSION_CIAM_PROGRESS
  });
};
